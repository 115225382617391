<template>
  <div
    style="align-self: center; padding-bottom: 3px; padding-right: 13px"
    class="filter-text"
  >
    {{ filterarea }} -
  </div>
  <div
    v-for="(datasets, i) in stackedData.datasets"
    :key="datasets"
    class="grid"
    style="align-items: center; margin-bottom: 4px; padding-top: 10px"
  >
    <div
      class="col-12 xl:col-1 md:col-12 lg:col-12 sm:col-12 .col-12 .col-sm-12"
      :style="{
        'background-color': stackedData.datasets[i].backgroundColor,
        height: '15px',
        width: '15px',
        color: stackedData.datasets[i].backgroundColor,
        'border-radius': '10px',
      }"
    ></div>
    <div
      class="col-12 xl:col-1 md:col-12 lg:col-12 sm:col-12 .col-12 .col-sm-12"
    >
      <span
        style="
          margin-right: 10px;
          font-size: 13px;
          padding-left: 3px;
          padding-right: 10px;
        "
        >{{ stackedData.datasets[i].label }}</span
      >
    </div>
  </div>
</template>

<script>

export default ({
    data() {
        
    },
    props: {
    stackedData: Object,
    filterarea: Object,
  },
})
</script>

