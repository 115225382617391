<template>
  <div class="grid">
    <div class="col-12 xl:col-12">
      <div class="card">
        <!-- INFO BAR ----------------------------------------------------------------------------------------------------------------------------------->

        <ProjectInfo />

        <!-- INFO BAR ----------------------------------------------------------------------------------------------------------------------------------->
        <!-- BODY HEADER ----------------------------------------------------------------------------------------------------------------------------------->
        <BodyHeader />
        <!-- BODY HEADER ----------------------------------------------------------------------------------------------------------------------------------->
        <!-- Accordion Tab ----------------------------------------------------------------------------------------------------------------------------------->
        <div class="grid" style="padding-top: 20px; justify-content: center">
          <div
            class="col-12 xl:col-12 sm:col-12 md:col-12 .col-12"
            style="text-align: center"
          >
            <div>
              <div
                class="grid"
                style="
                  box-sizing: border-box;
                  background: rgb(255, 255, 255);
                  border: 2px solid rgb(245, 246, 247);
                  box-shadow: rgb(0 0 0 / 25%) 0px 4px 4px;
                  border-radius: 18px;
                  justify-content: center;
                "
              >
                <div class="col-12"></div>
                <div
                  class="col-12 flex"
                  style="justify-content: center; align-items: center"
                >
                  <Image
                    src="/images/locIcon.svg"
                    alt="Image"
                    width="30"
                    style="padding-right: 10px; padding-top: 10px"
                  />
                  <h5
                    style="
                      font-style: normal;
                      font-weight: 600;
                      font-size: 15px;
                      line-height: 18px;
                      color: #466107;
                      display: flex;
                      align-items: center;
                      padding-right: 20px;
                    "
                  >
                    Todas as Áreas
                  </h5>
                  <Image
                    src="/images/logoVeg.svg"
                    alt="Image"
                    width="30"
                    style="padding-right: 10px; padding-top: 10px"
                  />
                  <h5
                    style="
                      font-style: normal;
                      font-weight: 600;
                      font-size: 15px;
                      line-height: 18px;
                      color: #466107;
                      display: flex;
                      align-items: center;
                    "
                  >
                    Vegetativo
                  </h5>
                </div>
                <div
                  style="
                    align-self: center;
                    writing-mode: tb;
                    transform: rotate(180deg);
                    transform-origin: right;
                    margin-right: 10px;
                  "
                >
                  <span>Frequencia (%)</span>
                </div>
                <div
                  class="col-12 xl:col-5 md:col-12 lg:col-12 sm:col-12 .col-12 .col-sm-12"
                >
                  <span class="titleChart">Controle</span>
                  <Chart
                    type="bar"
                    :data="stackedData.controle"
                    :options="stackedOptions"
                  />
                </div>
                <div
                  style="
                    align-self: center;
                    writing-mode: tb;
                    transform: rotate(180deg);
                    transform-origin: right;
                    margin-right: 10px;
                  "
                >
                  <span>Frequencia (%)</span>
                </div>
                <div
                  class="col-12 xl:col-5 md:col-12 lg:col-12 sm:col-12 .col-12 .col-sm-12"
                >
                  <span class="titleChart">Produto</span>
                  <Chart
                    type="bar"
                    :data="stackedData.produto"
                    :options="stackedOptions"
                  />
                </div>
                <div
                  class="col-6 xl:col-1 md:col-12 lg:col-12 sm:col-12"
                  style="width: 11%"
                >
                  <div v-if="project.typeProject === '01'">
                    <Button
                      label="Filtrar"
                      icon="pi pi-filter"
                      @click="openModal"
                    />
                    <Filter
                      :options="filterBasicFull"
                      @change="(f) => (filter = f)"
                      ref="filterEl"
                    />
                  </div>

                  <div
                    v-else-if="project.typeProject === '02'"
                    class="col-6 xl:col-1 md:col-12 lg:col-12 sm:col-12"
                  >
                    <Button
                      label="Filtrar"
                      icon="pi pi-filter"
                      @click="openModal"
                    />
                    <Filter
                      :options="filterBasicLimited"
                      @change="(f) => (filter = f)"
                      ref="filterEl"
                    />
                  </div>
                </div>
                <ListItens
                  :stackedData="stackedData.controle"
                  :filterarea="filterarea"
                />
              </div>
            </div>
          </div>
          <!-- Accordion Tab ----------------------------------------------------------------------------------------------------------------------------------->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectInfo from "@/components/ProjectInfo.vue";
import BodyHeader from "@/components/CommunityComposition/BodyHeader.vue";
import ListItens from "@/components/CommunityComposition/ListItens.vue";
import { useProjectsStore } from "@/store/projects";
import Filter from "@/components/global/Filter.vue";

export default {
  data() {
    return {
      filterBasicFull: {
        items: [
          {
            title: "Selecione o grupo taxonômico que deseja avaliar.",
            value: "taxonomico",
            options: [
              {
                label: "Bactérias",
                value: "ControleXProduto",
                image: "/images/CPIcon.svg",
                checked: true,
              },
              {
                label: "Fungos",
                value: "VegetativoxReprodutivo",
                image: "/images/VFIcon.svg",
              },
            ],
          },
          {
            title: "Selecione os filtros.",
            value: "area",
            options: [
              {
                label: "Filo",
                value: "0",
                checked: true,
              },
              {
                label: "Classe",
                value: "1",
              },
              {
                label: "Ordem",
                value: "2",
              },
              {
                label: "Família",
                value: "3",
              },
              {
                label: "Gênero",
                value: "3",
              },
            ],
          },

          {
            condition: (filter) => filter.taxonomico === "ControleXProduto",
            title: "Selecione o produto.",
            value: "estagio",
            options: [
              {
                label: "Vegetativo",
                value: "vegetativo",
                image: "/images/logoVeg.svg",
                checked: true,
              },
              {
                label: "Reprodutivo",
                value: "reprodutivo",
                image: "/images/florIcon.svg",
              },
            ],
          },

          {
            condition: (filter) => filter.taxonomico != "ControleXProduto",
            title: "Selecione o Produto.",
            value: "produto",
            options: [
              {
                label: "Controle",
                value: "controle",
                image: "/images/control.svg",
              },
              {
                label: "Produto",
                value: "produto",
                image: "/images/product.svg",
              },
            ],
          },
        ],
      },

      filterBasicLimited: {
        items: [
          {
            title: "Selecione o grupo taxonômico que deseja avaliar.",
            value: "taxonomico",
            options: [
              {
                label: "Controle X Produto",
                value: "ControleXProduto",
                image: "/images/CPIcon.svg",
                checked: true,
              },
            ],
          },
          {
            title: "Selecione a área.",
            value: "area",
            options: [
              {
                label: "Todas as Áreas",
                value: "0",
                checked: true,
              },
              {
                label: "Área 1",
                value: "1",
              },
              {
                label: "Área 2",
                value: "2",
              },
              {
                label: "Área 3",
                value: "3",
              },
            ],
          },

          {
            title: "Selecione o produto.",
            value: "estagio",
            options: [
              {
                label: "Vegetativo",
                value: "vegetativo",
                image: "/images/logoVeg.svg",
                checked: true,
              },
            ],
          },
        ],
      },
      projectsStore: useProjectsStore(),
      selectedType: null,
      loading: true,
      displayModal: false,
      filterarea: "Filo",
      filterType: "Controle",
      filterType2: "Vegetativo",
      stackedData: {
        controle: {
          labels: [
            "Área 1 - Londrina",
            "Área 2 - Maringá",
            "Área 3 - Arapongas",
          ],
          datasets: [
            {
              type: "bar",
              label: "Actinobacteriota",
              backgroundColor: "#42A5F5",
              data: [42.72480584, 36.87699886, 30.21069513],
            },
            {
              type: "bar",
              label: "Proteobacteria",
              backgroundColor: "#66BB6A",
              data: [26.70846199, 38.62315288, 27.34391417],
            },
            {
              type: "bar",
              label: "Firmicutes",
              backgroundColor: "#FFA726",
              data: [17.78640716, 18.67544413, 33.39821932],
            },
            {
              type: "bar",
              label: "Acidobacteriota",
              backgroundColor: "#83F8FF",
              data: [3.287483235, 3.93818428, 1.458828734],
            },
            {
              type: "bar",
              label: "Gemmatimonadota",
              backgroundColor: "#FF4545",
              data: [1.361467203, 1.323622102, 0.8029641099],
            },
            {
              type: "bar",
              label: "Outros",
              backgroundColor: "#DBFF00",
              data: [8.131374567, 0.5625977543, 6.785378532],
            },
          ],
        },
        produto: {
          labels: [
            "Área 1 - Londrina",
            "Área 2 - Maringá",
            "Área 3 - Arapongas",
          ],
          datasets: [
            {
              type: "bar",
              label: "Firmicutes",
              backgroundColor: "#42A5F5",
              data: [19.96067676, 13.35584785, 19.27942749],
            },
            {
              type: "bar",
              label: "Acidobacteriota",
              backgroundColor: "#66BB6A",
              data: [4.750181746, 5.737646641, 4.287241918],
            },
            {
              type: "bar",
              label: "Proteobacteria",
              backgroundColor: "#FFA726",
              data: [38.19972242, 49.93245645, 36.46623345],
            },
            {
              type: "bar",
              label: "Actinobacteriota",
              backgroundColor: "#83F8FF",
              data: [31.28180556, 24.68183434, 37.10290368],
            },
            {
              type: "bar",
              label: "Gemmatimonadota",
              backgroundColor: "#FF4545",
              data: [1.819113079, 2.004976893, 2.515423213],
            },
            {
              type: "bar",
              label: "Outros",
              backgroundColor: "#DBFF00",
              data: [3.98850043, 4.287237824, 0.3487702558],
            },
          ],
        },
      },

      stackedOptions: {
        plugins: {
          tooltip: {
            mode: "index",
            intersect: false,
          },
          legend: {
            position: "none",
            labels: {
              color: "#495057",
            },
          },
        },
        scales: {
          x: {
            stacked: true,
            ticks: {
              color: "#495057",
            },
            grid: {
              color: "#ebedef",
            },
          },
          y: {
            stacked: true,
            ticks: {
              color: "#495057",
            },
            grid: {
              color: "#ebedef",
            },
            max: 100,
          },
        },
      },

      // ###################################
    };
  },
  computed: {
    project() {
      var projectId = this.$route.params.projectId;
      return this.projectsStore.project(projectId);
    },
  },
  mounted() {
    this.verifyData();
  },
  beforeUnmount() {},
  created() {},
  methods: {
    verifyData() {
      if (this.project.typeProject != "01") {
        this.stackedData = this.stackedData2;
      } else {
        return this.changes;
      }
    },
    openModal() {
      this.$refs.filterEl.open();
    },
    closeModal() {
      this.$refs.filterEl.close();
    },
  },
  components: {
    ProjectInfo: ProjectInfo,
    BodyHeader: BodyHeader,
    ListItens,
    Filter,
  },
};
</script>

<style>

</style>
