<template>
  <div class="grid" style="padding-top: 20px">
    <div class="col-12 xl:col-12 sm:col-12 .col-12">
      <h3
        style="
          font-style: normal;
          font-weight: 900;
          font-size: 20px;
          line-height: 140.62%;
          color: rgba(0, 0, 0, 0.7);
        "
      >
        Composição da comunidade
      </h3>
      <h4
        style="
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 19px;
          color: #585857;
          text-align: justify;
        "
      >
        Frequência dos grupos taxonômicos que compõe a comunidade. São
        apresentados os 10 grupos mais abundantes.
      </h4>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
